import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom'
import * as Feather from 'react-feather';
import {getRedeems, cancel} from '../../services/redeem';
import UsedTag from '../../assets/svg/tags/used-tag.svg';

const MyRedeems = (props) => {
    const [redeems, setRedeems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nextPage, setNextPage] = useState(null);
    
    useEffect(() => {
        setRedeems([]);
        setNextPage(null);
        setLoading(false);
        requestRedeems();

        return () => {
            cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requestRedeems = async () => {
        setLoading(true);
        try {
            const data = await getRedeems(null || 6, nextPage || 1);
            setLoading(false);
            setRedeems(state => [...state, ...data.redeems]);
            setNextPage(data.nextPage);
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <>
        <div className="col-12 col-xl-8 col-xxl-9 mb-3">
            <div className="col-12 mb-3">
                <div className="border-bottom border-3 border-gray-200 pb-1 mb-4 mb-xl-5 mt-3 mt-xl-4">
                    <h3 className="font-weight-semibold pb-xl-4">Cupones</h3>
                </div>
                <div className="row">
                    {
                        ((!redeems || redeems.length === 0) && !loading) && (
                            <div className="text-center"><p className="fw-bold">No tenés cupones para mostrar</p></div>
                        )
                    }
                    {
                        redeems.map((transaction, index) => (
                            <div className="col-12 col-md-6" key={`${transaction._id}_${index}`}>
                                <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3">
                                    {
                                        transaction.usage && <img src={UsedTag} alt="Validado" className="position-absolute h-75 end-0 w-auto" />
                                    }
                                    <div className="card-body px-0 p-md-3 row d-flex justify-content-center align-items-center">
                                        <div className="col-auto d-flex align-items-center">
                                            <span  className="badge badge-size-xl p-0 rounded-24">
                                                {
                                                    transaction.promo?.images[0]?.url && <img src={transaction.promo.images[0].url} alt={transaction.title} className="rounded-24" />
                                                }
                                            </span>
                                        </div>
                                        <div className="col ps-0 ms-4">
                                            <h6 className="mb-1 font-weight-semibold">{transaction.promo.description_title}</h6>
                                            <small className="d-block"><b>Vencimiento:</b> {transaction.expiration_date ? new Date(transaction.expiration_date).toLocaleDateString('es-Ar') : 'Hace mucho tiempo'}</small>
                                        </div>
                                        <div className="col-auto pe-0 pe-md-3">
                                            <div className="text-end">
                                                <span className={`badge  rounded-pill py-2 ${transaction.usage ? 'bg-gray-100 text-gray-600' : 'bg-danger text-white'}`}>{transaction.code}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                    <div className='row'>
                        {loading && (
                            <div className="col-12 text-center">
                                <Feather.Loader className="align-self-center h3 mb-3 animate spin" />
                            </div>
                        )}
                        <div
                            className="col-12 text-center mb-3"
                            style={{
                                visibility: nextPage && !loading ? 'visible' : 'hidden'
                            }}
                        >
                            <button
                                className="btn btn-lg btn-primary px-md-4 mt-lg-3"
                                onClick={requestRedeems}
                            >
                                <span className="px-md-3">Cargar Más</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="alert-background redeem-background p-4 p-md-5 position-relative overflow-hidden rounded-12 mb-4" style={{ backgroundImage: `url('${props.assets.redeem_background_web}')`}}>
                    <div className="row mb-0 mb-sm-5 mb-md-0 ps-xl-3">
                        <div className="col-lg-12 col-xl-8 col-xxl-4 mb-md-5 mb-lg-0 position-relative z-index-top">
                            <NavLink to="/" className="badge badge-lg badge-primary text-uppercase">Ver Beneficios</NavLink>
                            <h2 className="my-2 ">Canjeá tus estrellas para acceder a los mejores beneficios</h2>
                        </div>
                    </div>
                    <div className="alert-overlay z-index-background"></div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = state => ({
    assets: state.assets,
});

export default connect(mapStateToProps)(MyRedeems);
