import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const getList = async () => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/relatives/getByUser`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const create = async (body) => {
    Store.dispatch({ type: 'LOADING' });
    try {
         /* CAMPOS DEL BODY
        {
            relative_dob, // FECHA
            relative_name, // STRING
            relative_gender, // ENUM, 'female', 'male'
            relationship, // ENUM, 'hijo', 'nieto', 'sobrino', 'otro'
        }
        */
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/relatives/create`, body);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const edit = async (id, body) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        /* CAMPOS DEL BODY
        {
            relative_dob, // FECHA
            relative_name, // STRING
            relative_gender, // ENUM, 'female', 'male'
            relationship, // ENUM, 'hijo', 'nieto', 'sobrino', 'otro'
        }
        */
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/relatives/edit/${id}`, body);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const remove = async (id) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/relatives/delete/${id}`);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};
