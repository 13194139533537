import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import swal2 from "sweetalert2";
//import AfterHeader from '../components/AfterHeader';
import { days, months, years } from "../utils/date-select";
//services
import { getList, cancel, create, edit, remove } from "../services/relatives";

//avatares
import avatar1 from "../assets/avatars/avatar1.svg";
import avatar2 from "../assets/avatars/avatar2.svg";
import avatar3 from "../assets/avatars/avatar3.svg";
import avatar4 from "../assets/avatars/avatar4.svg";
import avatar5 from "../assets/avatars/avatar5.svg";
//iconos de familiares
import DeleteUser from "../assets/delete-user.svg";
import WriteUser from "../assets/write-user.svg";

const dayOpts = days();
const yearsOpts = years();

const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5];

const relationships = {
    hijo: {
        male: "Hijo",
        female: "Hija",
    },
    nieto: {
        male: "Nieto",
        female: "Nieta",
    },
    sobrino: {
        male: "Sobrino",
        female: "Sobrina",
    },
    otro: {
        male: "Otro",
        female: "Otra",
    },
};

const defaultRelative = {
    relative_name: "", // STRING
    relative_gender: "male", // ENUM, 'female', 'male'
    relationship: "hijo", // ENUM, 'hijo', 'nieto', 'sobrino', 'otro',
    bday: new Date().getDate(),
    bmonth: new Date().getMonth(),
    byear: new Date().getFullYear(),
};

const ViewRelatives = (props) => {
    const [newRelative, setNewRelative] = useState(defaultRelative);
    const [editRelative, setEditRelative] = useState({});
    const [relatives, setRelatives] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = "Grisino Fan - Familiares";
        const getRelatives = async () => {
            const res = await getList();
            setRelatives(res.data);
        };
        getRelatives();
        return () => cancel();
    }, []);

    const handleNewRelativeInputChanges = (e) => {
        setNewRelative((state) => ({
            ...state,
            [e.target.id]: e.target.value,
        }));
    };

    const handleEditRelativeInputChanges = (e, field) => {
        setEditRelative((state) => ({
            ...state,
            [field]: e.target.value,
        }));
    };

    const handleNewRelativeDateInputChanges = (e) => {
        switch (e.target.id) {
            case "day":
                setNewRelative((state) => ({
                    ...state,
                    bday: e.target.value,
                }));
                break;
            case "month":
                setNewRelative((state) => ({
                    ...state,
                    bmonth: e.target.value,
                }));
                break;
            case "year":
                setNewRelative((state) => ({
                    ...state,
                    byear: e.target.value,
                }));
                break;
            default:
                break;
        }
    };

    const handleRelativeDateInputChanges = (e) => {
        switch (e.target.id) {
            case "day":
                setEditRelative((state) => ({
                    ...state,
                    relative_birthday_day: e.target.value,
                }));
                break;
            case "month":
                setEditRelative((state) => ({
                    ...state,
                    relative_birthday_month: e.target.value,
                }));
                break;
            case "year":
                setEditRelative((state) => ({
                    ...state,
                    relative_birthday_year: e.target.value,
                }));
                break;
            default:
                break;
        }
    };

    const createNewRelative = async (e, newRel) => {
        e.preventDefault();
        if (newRel?.relative_name === "") {
            setError("El nombre es requerido.");
            return;
        }

        const relative_dob = new Date(
            newRel.byear,
            parseInt(newRel.bmonth, 10),
            newRel.bday,
        );

        try {
            const res = await create({
                ...newRel,
                relative_dob,
            });

            setRelatives((state) => [...state, res.data]);

            setNewRelative(defaultRelative);
        } catch (err) {
            return swal({
                title: "Ups.",
                text: "No podemos guardar tu familiar en este momento.",
                icon: "error",
                confirmButtonColor: "#E80000",
                className: "logout-alert",
            });
        }
    };

    const submitRelativeChanges = async (e) => {
        e.preventDefault();
        if (!editRelative.relative_name) {
            setError("El nombre es requerido.");
            return;
        }

        const relative_dob = new Date(
            editRelative.relative_birthday_year,
            editRelative.relative_birthday_month,
            editRelative.relative_birthday_day,
        );

        try {
            const res = await edit(editRelative._id, {
                ...editRelative,
                relative_dob,
            });

            if (!res.success) {
                throw new Error("No se pudo completar la solicitud.");
            }

            const aux = [...relatives];
            const index = aux.findIndex((r) => r._id === editRelative._id);
            aux[index] = res.data;
            setRelatives([...aux]);
            setEditRelative({});
        } catch (err) {
            return swal({
                title: "Ups.",
                text: "No podemos guardar tu familiar en este momento.",
                icon: "error",
                confirmButtonColor: "#E80000",
                className: "logout-alert",
            });
        }
    };

    const removeRelative = async (id) => {
        swal2
            .fire({
                title: "Eliminar familiar",
                text: "¿Estás seguro de que deseas eliminar el familiar?",
                icon: "warning",
                showCancelButton: true,
                buttonsStyling: false,
                cancelButtonText: "No",
                confirmButtonText: "Si",
                reverseButtons: true,
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-light mx-2",
                },
            })
            .then(async ({ isConfirmed }) => {
                if (!isConfirmed) {
                    return;
                }
                try {
                    remove(id);
                    setRelatives((state) => state.filter((r) => r._id !== id));
                } catch (err) {
                    return swal({
                        title: "Ups.",
                        text: "No podemos eliminar tu familiar en este momento.",
                        icon: "error",
                        confirmButtonColor: "#DD6B55",
                        className: "logout-alert",
                    });
                }
            });
    };

    return (
        <>
            <div className="p-3 p-xxl-5">
                <div className="row">
                    {/* CARD AGREGAR FAMILIARES */}
                    <div className="col-sm">
                        <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
                            <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                                <h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">
                                    Agregar Familiar
                                </h5>
                            </div>
                            <div className="card-body px-0 p-md-4">
                                <form
                                    className="px-3 form-style-two"
                                    onSubmit={(e) => createNewRelative(e, newRelative)}
                                >
                                    <div className="row">
                                        <div className="col-12 mb-md-4 pb-3">
                                            <label
                                                htmlFor="first_name"
                                                className="form-label form-label-lg"
                                            >
                                                Nombre
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-xl"
                                                id="relative_name"
                                                placeholder="Nombre"
                                                value={newRelative?.relative_name}
                                                onChange={handleNewRelativeInputChanges}
                                            />
                                        </div>
                                        <div className="col-12 mb-md-4 pb-3">
                                            <label
                                                htmlFor="first_name"
                                                className="form-label form-label-lg"
                                            >
                                                Fecha de Nacimiento
                                            </label>
                                            <div className="input-group input-group-xl">
                                                <select
                                                    id="day"
                                                    className={`form-control form-control-xl `}
                                                    aria-label="Día"
                                                    value={newRelative.bday}
                                                    onChange={handleNewRelativeDateInputChanges}
                                                >
                                                    <option disabled>Día</option>
                                                    {dayOpts.map((o) => (
                                                        <option value={o.value} key={o.value}>
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="input-group-text">/</span>
                                                <select
                                                    id="month"
                                                    className={`form-control form-control-xl `}
                                                    aria-label="Mes"
                                                    value={newRelative.bmonth}
                                                    onChange={handleNewRelativeDateInputChanges}
                                                >
                                                    <option disabled>Mes</option>
                                                    {months.map((o) => (
                                                        <option value={o.value} key={o.value}>
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="input-group-text">/</span>
                                                <select
                                                    id="year"
                                                    className={`form-control form-control-xl `}
                                                    aria-label="Año"
                                                    value={newRelative.byear}
                                                    onChange={handleNewRelativeDateInputChanges}
                                                >
                                                    <option disabled>Año</option>
                                                    {yearsOpts.map((o) => (
                                                        <option value={o.value} key={o.value}>
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-md-4 pb-3">
                                            <label
                                                htmlFor="gender"
                                                className="form-label form-label-lg"
                                            >
                                                Género
                                            </label>
                                            <select
                                                id="relative_gender"
                                                className="form-control form-control-lg"
                                                value={newRelative.relative_gender}
                                                onChange={handleNewRelativeInputChanges}
                                            >
                                                <option value="male">Niño</option>
                                                <option value="female">Niña</option>
                                            </select>
                                        </div>
                                        <div className="col-12 mb-md-4 pb-3">
                                            <label
                                                htmlFor="relatives"
                                                className="form-label form-label-lg"
                                            >
                                                Parentezco
                                            </label>
                                            <select
                                                id="relationship"
                                                className="form-control form-control-lg"
                                                value={newRelative?.relationship}
                                                onChange={handleNewRelativeInputChanges}
                                            >
                                                <option value="hijo">Hijo/a</option>
                                                <option value="nieto">Nieto/a</option>
                                                <option value="sobrino">Sobrino/a</option>
                                                <option value="otro">Otro</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row py-md-3">
                                        <div className="col-lg-6 d-flex align-items-center">
                                            <div className="text-start">
                                                <p className="text-danger fw-bold">{error}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="text-md-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary px-md-4 mt-lg-3"
                                                >
                                                    <span className="px-md-3">Guardar</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* CARD LISTA FAMILIARES */}
                    <div className="col-sm">
                        <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
                            <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                                <h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">
                                    Listado de Familiares
                                </h5>
                            </div>
                            <div className="card-body px-0 p-md-4">
                                {!relatives.length ? <p className="row my-3 mx-auto justify-content-center align-self-center">Aún no tienes familiares</p> : relatives.map((relative, indexOfAvatar) => (
                                    <div
                                        className="row my-3 align-items-center"
                                        key={relative._id}
                                    >
                                        <div className="row align-items-center">
                                            <ul>
                                                <li key={relative._id} className="d-flex">
                                                    <div key={indexOfAvatar}>
                                                        <img
                                                            className="card-familiar-logo"
                                                            src={avatars[indexOfAvatar % avatars.length]}
                                                            alt="imagen de perfil"
                                                        />
                                                    </div>
                                                    {/* Detalles */}
                                                    <div className="col-sm-7 text-muted">
                                                        <h5>{relative?.relative_name}</h5>
                                                        <div className="row align-items-center">
                                                            <div className="col-md-auto">
                                                                {
                                                                    relationships[
                                                                    relative?.relationship.toLowerCase()
                                                                    ][relative.relative_gender]
                                                                }
                                                            </div>
                                                            <div className="col-md-auto">
                                                                {new Date(
                                                                    relative.relative_dob,
                                                                ).toLocaleDateString("es-AR")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Botones */}
                                                    <div className="col-sm-3">
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                className="card-familiar-btn"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#staticBackdrop"
                                                                onClick={() => setEditRelative({ ...relative })}
                                                            >
                                                                <img
                                                                    className="card-familiar-logo-last"
                                                                    src={WriteUser}
                                                                    alt="editar"
                                                                />
                                                            </button>
                                                            <button
                                                                className="card-familiar-btn"
                                                                onClick={() => removeRelative(relative._id)}
                                                            >
                                                                <img
                                                                    className="card-familiar-logo-last"
                                                                    src={DeleteUser}
                                                                    alt="eliminar"
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>                                                    
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* MODAL EDITAR FAMILIAR */}
            <div
                className={`modal modal-lg fade`}
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                Editar Familiar
                            </h1>
                        </div>
                        <div className="modal-body">
                            <form
                                className="px-3 form-style-two"
                                onSubmit={submitRelativeChanges}
                            >
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <label
                                            htmlFor="nameModal"
                                            className="form-label form-label-lg"
                                        >
                                            Nombre
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="nameModal"
                                            value={editRelative?.relative_name}
                                            onChange={(e) =>
                                                handleEditRelativeInputChanges(e, "relative_name")
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label htmlFor="first_name" className="form-label form-label-lg">
                                            Fecha de Nacimiento
                                        </label>
                                        <div className="input-group">
                                            <select
                                                id="day"
                                                className={`form-control form-control-lg`}
                                                value={editRelative.relative_birthday_day}
                                                onChange={handleRelativeDateInputChanges}
                                            >
                                                <option disabled>Día</option>
                                                {dayOpts.map((o) => (
                                                    <option value={o.value} key={o.value}>
                                                        {o.label}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="input-group-text">/</span>
                                            <select
                                                id="month"
                                                className={`form-control form-control-lg`}
                                                value={editRelative.relative_birthday_month}
                                                onChange={handleRelativeDateInputChanges}
                                            >
                                                <option disabled>Mes</option>
                                                {months.map((o) => (
                                                    <option value={o.value} key={o.value}>
                                                        {o.label}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="input-group-text">/</span>
                                            <select
                                                id="year"
                                                className={`form-control form-control-lg `}
                                                value={editRelative.relative_birthday_year}
                                                onChange={handleRelativeDateInputChanges}
                                            >
                                                <option disabled>Año</option>
                                                {yearsOpts.map((o) => (
                                                    <option value={o.value} key={o.value}>
                                                        {o.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label htmlFor="genderModal" className="form-label form-label-lg">
                                            Género
                                        </label>
                                        <select
                                            className="form-control"
                                            id="genderModal"
                                            value={editRelative.relative_gender}
                                            onChange={(e) =>
                                                handleEditRelativeInputChanges(e, "relative_gender")
                                            }
                                        >
                                            <option value="male">Niño</option>
                                            <option value="female">Niña</option>
                                        </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label htmlFor="relationshipModal" className="form-label form-label-lg">
                                            Parentezco
                                        </label>
                                        <select
                                            className="form-control"
                                            id="relationshipModal"
                                            value={editRelative?.relationship}
                                            onChange={(e) =>
                                                handleEditRelativeInputChanges(e, "relationship")
                                            }
                                        >
                                            <option value="hijo">Hijo/a</option>
                                            <option value="nieto">Nieto/a</option>
                                            <option value="sobrino">Sobrino/a</option>
                                            <option value="otro">Otro</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-custom-outline btn-outline mr-auto px-md-4 mt-lg-3 btn-long"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                    >
                                        Volver
                                    </button>
                                    <button
                                        type="submit"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                        className="btn btn-lg btn-primary px-md-4 mt-lg-3 btn-long"
                                    >
                                        <span className="px-md-3">Guardar</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    assets: state.assets,
    user: state.auth.user,
    loyalty: state.loyalty,
});

export default connect(mapStateToProps)(ViewRelatives);
