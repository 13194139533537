import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import * as Icon from 'react-feather';
import MyPoints from '../components/MyAccount/MyPoints';
import Missions from '../components/MyAccount/Missions';
import MyRedeems from '../components/MyAccount/MyRedeems';
import { getLoyaltyStatus, getLoyaltyTransactions, cancelPoints, cancelTransactions } from '../services/loyalty';

import GrisinoFan from '../assets/svg/brand/grisinofan.svg';

const MyAccount = (props) => {
    useEffect(() => {
        document.title = 'Grisino Fan - Tu Cuenta';
    }, []);

    let {tab} = useParams();
    if (!tab) {
        tab = '/'
    }

    const [transactions, setTransactions] = useState([]);
    const [sLoading, setSLoading] = useState(true);
    const [tLoading, setTLoading] = useState(true);

    useEffect(() => {
        getPoints();
        getTransactions();

        return () => {
            cancelPoints();
            cancelTransactions();
        }
    }, []);

    const getTransactions = async () => {
        setTLoading(true);
        try {
            const t = await getLoyaltyTransactions();
            setTransactions(t.data);
            setTLoading(false);
        } catch (err) {
        }
    };

    const getPoints = async () => {
        setSLoading(true);

        await getLoyaltyStatus();

        setSLoading(false);
    };

    return (
        <>
            <div className="p-3 p-xxl-5">
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12 col-xl-4 col-xxl-3 mb-3">
                        <div className="card mb-4 rounded-12 shadow border border-gray-50">
                            <div className="card-body p-3 px-xxl-4">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <span className="badge badge-size-xl rounded-24">
                                            <img src={GrisinoFan} height="50" alt="estrellas" />
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="caption text-gray-600 d-block mb-1">Estrellas disponibles</span>
                                        {
                                            props.sLoading ? <Icon.Loader className="mt-2 mb-0 animate spin" /> : <>
                                                <h3 className="h3 mb-0 primary-color">
                                                    {props.loyalty.points}
                                                </h3>
                                                {
                                                    props.loyalty.points > 0 &&
                                                    <span className="small text-gray-600">
                                                        Vencen el {new Date(props.user.rewardScoreExpiration).toLocaleDateString('es-Ar')}
                                                    </span>
                                                }
                                                
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                            <aside className="card border border-gray-200 rounded-12 mb-xl-4">
                                <div className="p-3 p-md-4 d-flex align-items-center">                                
                                    <div className="ps-2 ps-md-3">
                                        <h5 className="mb-1">{props.user.name}</h5>
                                        <p className="small text-gray-600 mb-0 lh-base">DNI {props.user.national_identity}</p>
                                    </div>
                                    <button className="navbar-toggler collapsed ms-auto d-block d-xl-none p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav2" aria-controls="navbarNav2" aria-expanded="false" aria-label="Toggle navigation">
                                        <svg className="menu-icon" data-name="icons/tabler/hamburger" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                            <rect data-name="Icons/Tabler/Hamburger background" width="16" height="16" fill="none"></rect>
                                            <path d="M15.314,8H.686A.661.661,0,0,1,0,7.368a.653.653,0,0,1,.593-.625l.093-.006H15.314A.661.661,0,0,1,16,7.368a.653.653,0,0,1-.593.626Zm0-6.737H.686A.661.661,0,0,1,0,.632.654.654,0,0,1,.593.005L.686,0H15.314A.661.661,0,0,1,16,.632a.653.653,0,0,1-.593.625Z" transform="translate(0 4)" fill="#1e1e1e"></path>
                                        </svg>
                                        <svg className="menu-close" data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                            <rect data-name="Icons/Tabler/Close background" width="16" height="16" fill="none"></rect>
                                            <path d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z" transform="translate(2 2)" fill="#1e1e1e"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="collapse navbar-collapse d-xl-block" id="navbarNav2">
                                    <ul className="sidebar-nav">
                                        <li>
                                            <NavLink to="/mi-cuenta" activeClassName="active" className="d-flex align-items-center" exact>
                                                <Icon.Star className="me-2" size={16} />
                                                <span className="ps-1">Movimientos</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/mi-cuenta/misiones" activeClassName="active" className="d-flex align-items-center">
                                                <Icon.Target className="me-2" size={16} />
                                                <span className="ps-1">Misiones</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/mi-cuenta/cupones" activeClassName="active" className="d-flex align-items-center">
                                                <Icon.Gift className="me-2" size={16} />
                                                <span className="ps-1">Cupones</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        {
                            tab === '/' && <MyPoints {...props.loyalty} transactions={transactions} tLoading={tLoading} sLoading={sLoading} />
                        }
                        {
                            tab === 'misiones' && <Missions {...props.loyalty} transactions={transactions} tLoading={tLoading} sLoading={sLoading} />
                        }
                        {
                            tab === 'cupones' && <MyRedeems />
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => ({
    loyalty: state.loyalty,
    user: state.auth.user,
    assets: state.assets,
})

const mapDispatchToProps = (dispatch) => ({
    setPoints: (points) => dispatch({ type: 'POINTS', points })
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
