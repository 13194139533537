import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import OneSignal from 'react-onesignal';
import { cancel, getList, markAsRead } from '../services/notifications';
import AfterHeader from '../components/AfterHeader';
import { Loader } from 'react-feather';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';

timeago.register('es', es);

const Notifications = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [permission, setPermission] = useState('default');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const res = await getList();
            setNotifications(res.notifications);
            setLoading(false);
        };
        const getPermission = async () => {
            const res = await OneSignal.getNotificationPermission();
            // default | granted | denied
            setPermission(res);
        }
        getData();
        getPermission();

        OneSignal.on('notificationPermissionChange', function(permissionChange) {
            setPermission(permissionChange.to);
        });

        document.title = 'Grisino Fan - Notificaciones';
        return () => cancel();
    }, []);

    useEffect(() => {
        const sendDataToOneSignal = async () => {
          await OneSignal.setExternalUserId(props.user._id);
          await OneSignal.setSubscription(true);
          await OneSignal.sendTags({
            last_name: props.user.last_name,
            first_name: props.user.first_name,
          });
        }
    
        if (permission === 'granted') {
          sendDataToOneSignal();
        }
      }, [permission, props.user._id, props.user.first_name, props.user.last_name]);

    const markAllAsRead = async () => {
        try {
            await markAsRead(notifications.map(a => a._id));

            const notif = [...notifications]
            notif.forEach(el => { el.read = true })
            setNotifications(notif)
            props.setUnread(0)
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const read = async (id) => {
        try {
            await markAsRead([id])
            
            const notif = [...notifications]
            notif.forEach(el => {
                if (el._id === id) {
                    el.read = true
                }
            })
            
            setNotifications(notif)
            if(props.unread > 0){
                props.setUnread(props.unread - 1)
            }
        } catch (err) {

        }
    }

    const checkAllBtn = () => {
        return (
            <button className={`btn btn-lg btn-primary ${!props.unread ? "disabled" : ""}`} cursor='pointer' onClick={markAllAsRead} >Marcar todos como leidos</button>
        )
    }

    const askPermission = () => {
        OneSignal.showNativePrompt();
    };

    return (
        <div>
            <AfterHeader text="Notificaciones" small={"Enterate de todas las novedades"} button={checkAllBtn} />
            <div className="container-fluid px-0">
                <div className="row p-md-5 p-3">
                    {
                        permission !== 'granted' &&
                        <div className="col-12">
                            <div className="card mb-4 rounded-12 shadow border border-gray-50 d-flex flex-row p-3 align-items-center justify-content-between" style={{ backgroundColor: '#E8000073' }}>
                                <p className="text-white mb-0">Activá las notificaciones en este dispositivo!</p>
                                <button className="btn btn-primary" onClick={askPermission}>Activar</button>
                            </div>
                        </div>
                    }
                    {loading ? <Loader className="mt-2 mb-0 animate spin" /> :
                        notifications.map(notif => (
                            <div className="col-md-10 col-lg-8 col-xl-6 col-12" key={notif._id}>
                                <div className="card mb-4 rounded-12 shadow border border-gray-50">
                                    <a href={notif?.url} target='_blank' onClick={() => read(notif._id)} rel="noreferrer">
                                        <div className="card-body p-3 px-xxl-4">
                                            <div className="row align-items-center">
                                                <div className="col pb-1">
                                                    <span className="h5 mb-0" onClick={() => read(notif._id)}>{notif.title}</span>
                                                    <span className="text-gray-700 d-block mb-1 pb-2">{notif.message}</span>
                                                    <TimeAgo className='caption text-muted pb-2' datetime={new Date(notif.start_date)} locale='es' />
                                                </div>
                                                <div className="col-1 mr-2">
                                                    {!notif.read && <div className='custom-blob bg-primary' ></div>}
                                                </div>
                                                <div className="col-2">
                                                    {notif.content?.images[0]?.url &&
                                                        <div style={{ aspectRatio: 1 }}>
                                                            <img alt={notif.title} src={notif.content.images[0].url} className="card-img-top" />
                                                        </div>
                                                    }
                                                    {notif.promo?.images[0]?.url &&
                                                        <div style={{ aspectRatio: 1 }}>
                                                            <img alt={notif.title} src={notif.promo.images[0].url} className="card-img-top" />
                                                        </div>
                                                    }
                                                    {notif.scren?.images[0]?.url &&
                                                        <div style={{ aspectRatio: 1 }}>
                                                            <img alt={notif.title} src={notif.screen.images[0].url} className="card-img-top" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    unread: state.notifications,
});

const mapDispatchToProps = dispatch => ({
    setUnread: (unread) => dispatch({ type: 'SET', unread })
 });

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
