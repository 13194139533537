import React from "react";
import { connect } from "react-redux";

import Familiarlogo from "../assets/familiar.svg";

const AddRelatives = ({ setAddRelative }) => {
    return (
        <>
            <div className="p-3 p-xxl-5">
                <div className="container-fluid px-0">
                    <div className="row" id="familiares-page">
                        <div className="col-sm">
                            <h4>👨‍👩‍👧‍👦 ¡Consentí a tu familia!</h4>
                            <p>
                                ¿Tenés hijos, sobrinos o nietos? Agregalos a tu lista de
                                familiares para que podamos sugerirte prendas ideales dirigidas
                                a cada uno de ellos! Además, vas a poder disfrutar de descuentos
                                exclusivos en los regalitos de cumple!
                            </p>
                        </div>
                        <div className="col-sm">
                            <h4>💡 ¿Cómo funciona?</h4>
                            <p>
                                Nos contás quiénes son, su fecha de nacimiento y el género. Con
                                esta información, la app te ayudará a encontrar opciones
                                personalizadas según su edad, preferencias y crecimiento.
                            </p>
                        </div>
                        <div className="col-sm">
                            <h4>📲 Empezá ahora</h4>
                            <p>
                                Hacé clic en el botón de abajo y agregá a tu primer familiar.
                                ¡En pocos minutos tendrás todo listo para empezar a disfrutar
                                beneficios para tu familia! Además, te regalamos estrellas por
                                completar esta misión!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="familiares-zone-btn">
                    <button
                        onClick={() => setAddRelative(true)}
                        className="familiares-btn"
                    >
                        <img src={Familiarlogo} alt="familiar" className="logofamiliares" />
                        Agregar mi primer familiar
                    </button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    assets: state.assets,
    user: state.auth.user,
    loyalty: state.loyalty,
});

export default connect(mapStateToProps)(AddRelatives);
