import React from 'react';
import * as Feather from 'react-feather';
import Icon from './TransactionIcon';
import {timeAgo} from '../../utils/dates';

const MyPoints = (props) => {
    return (
        <>
        <div className="col-12 col-xl-8 col-xxl-9 mb-3">
            <div className="row">
                <div className="col-12">
                    <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
                        <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                            <h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Tu Historial de Estrellas</h5>
                        </div>
                        <div className="card-body px-0 p-md-4">
                            <div className="list-group list-group-flush my-n3">
                            {
                                (!props.transactions || props.transactions.length === 0) && (
                                    <div className="text-center">{
                                        (props.tLoading) ? (
                                            <Feather.Loader className="h3 mb-0 animate spin" />
                                        ) : (
                                            <p className="fw-bold">Nada para mostrar</p>
                                        )
                                    }</div>
                                )
                            }
                            {
                                props.transactions.map((transaction) => (
                                    <div className="list-group-item px-4" key={transaction.id}>
                                        <div className="row align-items-center px-1 px-md-2">
                                            <div className="col ps-0">
                                                <h6 className="mb-1 font-weight-semibold">{transaction.name}</h6>
                                                <small>{timeAgo(transaction.createdAt)}</small>
                                            </div>
                                            <div className="col-auto pe-0 pe-md-3">
                                                <div className="text-end">
                                                    { transaction.score > 0 && <Icon.Positive /> }
                                                    { transaction.score < 0 && <Icon.Negative /> }
                                                    <span className="badge rounded-pill py-2 bg-gray-100 text-gray-600">{transaction.score}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <small>Recordá que las estrellas pueden tardar hasta 72 hs hábiles en asignarse.</small>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default MyPoints;
