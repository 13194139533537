import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AfterHeader from "../components/AfterHeader";
import { getShopsListSignIn, updateUser } from "../services/user";
import { days, months, years } from "../utils/date-select";
import { NavLink } from "react-router-dom";
import { getList } from "../services/relatives";
import { getCities, getProvinces } from "../services/user";

const fields = [
  "first_name",
  "last_name",
  "birthday",
  "phone_number",
  "fields",
];
const additionalFields = ["state", "city", "shop"];

const dayOpts = days();
const yearsOpts = years();

const Profile = (props) => {
  const [user, setUser] = useState(props.user);
  const [inputError, setInputError] = useState(null);
  const [error, setError] = useState(null);
  const [bday, setBday] = useState(null);
  const [relatives, setRelatives] = useState(0);
  const [bmonth, setBmonth] = useState(null);
  const [byear, setByear] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [shopsOptions, setShopsOptions] = useState([]);

  useEffect(() => {
    document.title = "Grisino Fan - Tu Perfil";
  }, []);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    const getRelatives = async () => {
      const res = await getList();
      setRelatives(res?.data?.length || 0);
    };
    getRelatives();
  }, [props.user]);

  // FETCH PROVINCES & CITIES
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const res = await getProvinces();
        setStateOptions(
          res.provincias
            .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
            .map((e) => ({ label: e.name, value: e.name })),
        );
      } catch (err) {
        console.log("fetch states error", err);
      }
    };
    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        if (user?.fields?.state) {
          const res = await getCities(user.fields.state);
          setCityOptions(
            res.localidades
              .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
              .map((e) => ({ label: e.name, value: e.name })),
          );
        }
      } catch (err) {
        console.log("fetch cities error", err);
      }
    };
    fetchCities();
  }, [user?.fields?.state]);
  // ----------------------------

  useEffect(() => {
    const getData = async () => {
      try {
        let d = await getShopsListSignIn();
        setShopsOptions(
          d.shops
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((e) => ({ label: e.name, value: e.name })),
        );
      } catch (err) {
        console.log("fetch error", err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (bday && bmonth && byear) {
      const b = new Date(byear, bmonth, bday);
      if (b.getDate() === parseInt(bday, 10)) {
        setInputError(null);
        setError(null);
        setUser((state) => ({
          ...state,
          birthday: b,
        }));
      } else {
        setUser((state) => ({
          ...state,
          birthday: "",
        }));
        setInputError("birthday");
        setError("La fecha ingresada no es válida.");
      }
    }
  }, [bday, bmonth, byear]);

  const handleInputChanges = (e) => {
    setUser((state) => ({
      ...state,
      [e.target.id]: e.target.value,
    }));
  };

  const handleInputFieldChanges = (e) => {
    setUser((state) => ({
      ...state,
      fields: {
        ...state.fields,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const handleDateInputChanges = (e) => {
    switch (e.target.id) {
      case "day":
        setBday(e.target.value);
        break;
      case "month":
        setBmonth(e.target.value);
        break;
      case "year":
        setByear(e.target.value);
        break;
      default:
        break;
    }
  };

  const Submit = async (e) => {
    e.preventDefault();

    setError(null);

    const newUser = {
      fields: { ...props.user.fields },
    };

    for (let i in fields) {
      if (
        props.user[fields[i]] &&
        (!user[fields[i]] || user[fields[i]].length < 2)
      ) {
        setInputError(fields[i]);
        return setError("Por favor, revisá los datos ingresados.");
      }

      if (!props.user.birthday && fields[i] === "birthday") {
        const thisYear = new Date().getFullYear();
        const inputYear = new Date(user.birthday).getFullYear();

        if (thisYear <= inputYear || thisYear - inputYear > 114) {
          setInputError(fields[i]);
          return setError("Por favor, ingresá una fecha de nacimiento válida.");
        }

        if (thisYear - inputYear <= 13) {
          setInputError(fields[i]);
          return setError("Debes ser mayor de 13 años para crear una cuenta.");
        }
      }

      if (fields[i] === "fields") {
        for (let j in additionalFields) {
          if (
            !user[fields[i]][additionalFields[j]] ||
            user[fields[i]][additionalFields[j]] === "" ||
            user[fields[i]][additionalFields[j]].length < 2
          ) {
            setInputError(fields[i][additionalFields]);
            return setError("Por favor, completá todos los campos.");
          }
          newUser.fields[additionalFields[j]] =
            user.fields[additionalFields[j]];
        }
      } else {
        newUser[fields[i]] = user[fields[i]];
      }
    }

    try {
      await updateUser(newUser);
    } catch (err) {
      setError("Lo sentimos, no podemos actualizar tus datos en este momento.");
    }
  };

  return (
    <>
      <AfterHeader text="Tu Perfil" small={"Actualizá tus datos!"} />
      <div className="p-3 p-xxl-5">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">
              <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5 overflow-hidden">
                <div
                  style={{
                    background:
                      "linear-gradient(to bottom, #E80000 50%, white 50%)",
                  }}
                  className="card-body px-5 position-relative"
                >
                  <div className="">
                    <span
                      style={{ borderWidth: 2, borderColor: "#E80000" }}
                      className="avatar avatar-xl avatar-border-xxl avatar-circle "
                    >
                      <img
                        className="avatar-img"
                        src={props?.assets?.default_user_avatar}
                        alt="Avatar"
                      />
                    </span>
                    <div className="row">
                      <div className="col-12">
                        <h3 className="mb-1">
                          {props.user.name}&nbsp;
                          <svg
                            className="ml-1"
                            data-name="Group 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="25.19"
                            viewBox="0 0 24 23.25"
                          >
                            <path
                              d="M23.823,11.991a.466.466,0,0,0,0-.731L21.54,8.7c-.12-.122-.12-.243-.12-.486L21.779,4.8c0-.244-.121-.609-.478-.609L18.06,3.46c-.12,0-.36-.122-.36-.244L16.022.292a.682.682,0,0,0-.839-.244l-3,1.341a.361.361,0,0,1-.48,0L8.7.048a.735.735,0,0,0-.84.244L6.183,3.216c0,.122-.24.244-.36.244L2.58,4.191a.823.823,0,0,0-.48.731l.36,3.412a.74.74,0,0,1-.12.487L.18,11.381a.462.462,0,0,0,0,.732l2.16,2.437c.12.124.12.243.12.486L2.1,18.449c0,.244.12.609.48.609l3.24.735c.12,0,.36.122.36.241l1.68,2.924a.683.683,0,0,0,.84.244l3-1.341a.353.353,0,0,1,.48,0l3,1.341a.786.786,0,0,0,.839-.244L17.7,20.035c.122-.124.24-.243.36-.243l3.24-.734c.24,0,.48-.367.48-.609l-.361-3.413a.726.726,0,0,1,.121-.485Z"
                              fill="#0D6EFD"
                            ></path>
                            <path
                              data-name="Path"
                              d="M4.036,10,0,5.8,1.527,4.2,4.036,6.818,10.582,0,12,1.591Z"
                              transform="translate(5.938 6.625)"
                              fill="#fff"
                            ></path>
                          </svg>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
                <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                  <h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">
                    Tus Datos
                  </h5>
                </div>
                <div className="card-body px-0 p-md-4">
                  <form className="px-3 form-style-two" onSubmit={Submit}>
                    <div className="row">
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="first_name"
                          className="form-label form-label-lg"
                        >
                          Nombre
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-xl"
                          id="first_name"
                          placeholder="Nombre"
                          value={user.first_name}
                          onChange={handleInputChanges}
                        />
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="last_name"
                          className="form-label form-label-lg"
                        >
                          Apellido
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-xl"
                          id="last_name"
                          placeholder="Apellido"
                          value={user.last_name}
                          onChange={handleInputChanges}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="email"
                          className="form-label form-label-lg"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-xl"
                          id="email"
                          placeholder="Email"
                          value={user.email}
                          readOnly
                          style={{
                            backgroundColor: "#e9ecef",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="documento"
                          className="form-label form-label-lg"
                        >
                          Documento
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-xl"
                          id="documento"
                          placeholder="Documento"
                          value={user.national_identity}
                          readOnly
                          style={{
                            backgroundColor: "#e9ecef",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="state"
                          className="form-label form-label-lg"
                        >
                          Provincia
                        </label>
                        <select
                          type="text"
                          className="form-control form-control-xl"
                          id="state"
                          placeholder="Seleccione una provincia"
                          value={user.fields.state}
                          onChange={handleInputFieldChanges}
                        >
                          <option value=""> </option>
                          {stateOptions.map((state) => (
                            <option key={state.label} value={state.label}>
                              {state.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="city"
                          className="form-label form-label-lg"
                        >
                          Ciudad
                        </label>
                        <select
                          type="text"
                          className="form-control form-control-xl"
                          id="city"
                          placeholder="Seleccione una Ciudad"
                          value={user.fields.city}
                          onChange={handleInputFieldChanges}
                        >
                          <option value=""> </option>
                          {cityOptions.map((city) => (
                            <option key={city.label} value={city.label}>
                              {city.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="shop"
                          className="form-label form-label-lg"
                        >
                          Local preferido
                        </label>
                        <select
                          type="text"
                          className="form-control form-control-xl"
                          id="shop"
                          placeholder="Seleccione un local"
                          value={user.fields.shop}
                          onChange={handleInputFieldChanges}
                        >
                          <option value=""> </option>
                          {shopsOptions.map((shop) => (
                            <option key={shop.label} value={shop.label}>
                              {shop.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="relatives"
                          className="form-label form-label-lg"
                        >
                          Familiares
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-xl"
                          id="relatives"
                          value={relatives}
                          readOnly
                          style={{
                            backgroundColor: "#e9ecef",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="first_name"
                          className="form-label form-label-lg"
                        >
                          Fecha de Nacimiento
                        </label>
                        {props.user.birthday ? (
                          <input
                            type="text"
                            className="form-control form-control-xl"
                            id="birthday"
                            placeholder="Fecha de Nacimiento"
                            style={{
                              backgroundColor: "#e9ecef",
                              cursor: "not-allowed",
                            }}
                            value={new Date(user.birthday).toLocaleDateString(
                              "es-Ar",
                            )}
                            readOnly
                          />
                        ) : (
                          <div className="input-group input-group-xl">
                            <select
                              id="day"
                              className={`form-control form-control-xl ${inputError === "birthday" ? "is-invalid" : ""}`}
                              aria-label="dia"
                              onChange={handleDateInputChanges}
                              style={{
                                backgroundColor: "#e9ecef",
                                cursor: "not-allowed",
                              }}
                            >
                              <option selected disabled>
                                Día
                              </option>
                              {dayOpts.map((o) => (
                                <option value={o.value} key={o.value}>
                                  {o.label}
                                </option>
                              ))}
                            </select>
                            <span className="input-group-text">/</span>
                            <select
                              id="month"
                              className={`form-control form-control-xl ${inputError === "birthday" ? "is-invalid" : ""}`}
                              aria-label="mes"
                              onChange={handleDateInputChanges}
                              style={{
                                backgroundColor: "#e9ecef",
                                cursor: "not-allowed",
                              }}
                            >
                              <option selected disabled>
                                Mes
                              </option>
                              {months.map((o) => (
                                <option value={o.value} key={o.value}>
                                  {o.label}
                                </option>
                              ))}
                            </select>
                            <span className="input-group-text">/</span>
                            <select
                              id="year"
                              className={`form-control form-control-xl ${inputError === "birthday" ? "is-invalid" : ""}`}
                              aria-label="año"
                              onChange={handleDateInputChanges}
                              style={{
                                backgroundColor: "#e9ecef",
                                cursor: "not-allowed",
                              }}
                            >
                              <option selected disabled>
                                Año
                              </option>
                              {yearsOpts.map((o) => (
                                <option value={o.value} key={o.value}>
                                  {o.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 mb-md-4 pb-3">
                        <label
                          htmlFor="phone_number"
                          className="form-label form-label-lg"
                        >
                          Teléfono
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-xl"
                          id="phone_number"
                          placeholder="Teléfono"
                          value={user.phone_number}
                          onChange={handleInputChanges}
                        />
                      </div>
                    </div>
                    <div className="row py-md-3">
                      <div className="col-lg-6 d-flex align-items-center">
                        <div className="text-start">
                          <p className="text-danger fw-bold">{error}</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row align-items-end">
                          <div className="d-flex justify-content-end gap-2">
                            <NavLink to="/familiares">
                              <button className="btn btn-lg px-md-4 mt-lg-3 btn-custom-outline">
                                <span className="px-md-3">
                                  Agregar Familiar
                                </span>
                              </button>
                            </NavLink>
                            <button
                              type="button"
                              className="btn btn-lg btn-primary px-md-4 mt-lg-3"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <span className="px-md-3">Guardar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL GUARDAR CAMBIOS */}
                    <div
                      className="modal modal-lg fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1
                              className="modal-title fs-5"
                              id="staticBackdropLabel"
                            >
                              Editar Datos
                            </h1>
                          </div>
                          <div className="modal-body">
                            <p>
                              ¿Estás seguro de que quieres actualizar tus datos?
                            </p>
                            <div className="modal-footer justify-content-between">
                              <button
                                type="button"
                                onClick={() => window.location.reload()}
                                className="btn btn-lg btn-custom-outline btn-outline mr-auto px-md-4 mt-lg-3 btn-long"
                                data-bs-dismiss="modal"
                              >
                                Cancelar
                              </button>
                              <button
                                type="submit"
                                onClick={Submit}
                                className="btn btn-lg btn-primary px-md-4 mt-lg-3 btn-long"
                                data-bs-dismiss="modal"
                                data-bs-toggle="modal"
                                data-bs-target="#confirmModal"
                              >
                                <span className="px-md-3">Confirmar</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL datos guardados */}
                    <div
                      className="modal fade"
                      id="confirmModal"
                      tabIndex="-1"
                      aria-labelledby="confirmModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1
                              className="modal-title fs-5"
                              id="confirmModalLabel"
                            >
                              Datos Guardados
                            </h1>
                          </div>
                          <div className="modal-body">
                            <p>
                              Tus datos han sido actualizados correctamente.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              Aceptar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  assets: state.assets,
  user: state.auth.user,
  loyalty: state.loyalty,
});

export default connect(mapStateToProps)(Profile);
