import React, { useEffect, useState } from "react";
import AfterHeader from "../components/AfterHeader";
import { getList, cancel } from "../services/relatives";

//screens
import ViewRelatives from "./ViewRelatives";
import AddRelatives from "./AddRelatives";

const Relatives = () => {
    const [initialized, setInitialized] = useState(false);
    const [addRelative, setAddRelative] = useState(false);
    const [relatives, setRelatives] = useState([]);

    useEffect(() => {
        document.title = "Grisino Fan - Familiares";

        const getRelatives = async () => {
            const res = await getList();
            setRelatives(res.data);
            setInitialized(true);
        };

        getRelatives();

        return () => cancel();
    }, []);

    return (
        <>
            <AfterHeader text="Familiares" small={"SUMÁ A TUS FAMILIARES"} />
            {!initialized ? null : relatives.length === 0 && !addRelative ? (
                <AddRelatives setAddRelative={setAddRelative} />
            ) : (
                <ViewRelatives />
            )}
        </>
    );
};

export default Relatives;
