import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Confetti from 'react-dom-confetti';
import Analytics from '../../utils/analytics';

const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

const RedeemModal = (props) => {
    const [copy, setCopy] = useState(false);

    useEffect(() => {
        if (props.promo._id && props.show) {
            Analytics.sendCustomEvent('redeem', 'promo', props.promo._id);
        }
    }, [props.promo._id, props.show]);

    useEffect(() => {
        if (copy) {
            setTimeout(() => setCopy(false), 2000);
        }
    }, [copy])

    return (
        <div className={`modal fade ${props.show && 'show d-block bg-darkened'}`}>
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <Confetti active={props.show} config={config} />
                <div className="modal-content shadow-dark-80">
                    <div className="modal-header border-0 pb-0 justify-content-center align-items-center ps-4 mb-2">
                        <h5 className="modal-title pt-3" id="exampleModalLabel">Felicitaciones!</h5>
                    </div>
                    <div className="modal-body pt-2 px-4 justify-content-center text-center">
                        {
                            props.redeem.pointsSubstracted > 0 && (
                                <p>Canjeaste {props.redeem.pointsSubstracted} estrellas!</p>
                            )
                        }
                        <p>Tu código es:</p>
                        {
                            copy ? (
                                <div className="d-flex justify-content-center align-items-center align-content-center redeemed-code border rounded-2 position-relative my-3">
                                    <span className="py-2 user-select-none">Copiado!</span>
                                    <Icon.Copy size={25} className="position-absolute" style={{ right: 10 }} />
                                </div>
                            ) : (
                                <CopyToClipboard text={props.redeem.code} onCopy={() => setCopy(true)}>
                                    <div className="d-flex justify-content-center align-items-center align-content-center redeemed-code border rounded-2 position-relative my-3">
                                        <span className="py-2 user-select-none">{props.redeem.code}</span>
                                        <Icon.Copy size={25} className="position-absolute" style={{ right: 10 }} />
                                    </div>
                                </CopyToClipboard>
                            )
                        }
                        <p>{props.more_info || 'Ya podés utilizarlo!'}</p>
                    </div>
                    {
                        props.promo.ecommerce_url && 
                        <div className="modal-footer row">
                            <a href={props.promo.ecommerce_url} target="_blank" rel="noreferrer" className="btn btn-link mb-2"><span className="px-1">Ir a la web</span></a>
                        </div>
                    }
                    <div className="modal-footer row">
                        <button type="button" className="btn btn-link text-danger mb-2" onClick={() => props.setShow(false)}><span className="px-1">Cerrar</span></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RedeemModal;
