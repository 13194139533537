import React, { useEffect } from 'react';
import {connect} from 'react-redux';

const Terms = () => {
    useEffect(() => {
        document.title = 'Grisino Fan - Términos y Condiciones';
    }, []);
    
    return (
        <>
            <div className="container-fluid fs-overlay">
                <div className="row">
                    <div className="p-3 p-xxl-5 bg-primary after-header">
                        <div className="container-fluid px-0 pb-5">
                            <div className="row">
                                <div className="col-md-10 col-lg-12 col-xxl-6 text-center pb-1 pt-4 px-4 mx-auto">
                                    <h1 className="display-4 text-white mb-2">Términos y Condiciones</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <p>
                                El Programa Grisino FAN (en adelante, el “Programa”) premia la fidelidad de los clientes posibilitando a los mismos a obtener diversos beneficios (en adelante, los “Beneficios”). El cliente podrá ser parte de Grisino FAN y acceder a los Beneficios que estarán disponibles en los locales adheridos y en el E-Shop de acuerdo a los siguientes Términos y Condiciones que seguidamente se detallan:
                            </p>
                            <h2>1) ADHESIÓN</h2>
                            <p>
                                <strong>1.1.</strong> Podrá participar del Programa toda persona física mayor de dieciocho años de edad, que resida en el territorio de la República Argentina y que haya solicitado su adhesión al Programa a través de la APP Grisino FAN. Se considerará Titular de los Beneficios del Programa (en adelante, el “Titular”) a aquellas personas cuya solicitud de adhesión al Programa haya sido aceptada por CRESKOTEC S.A. (en adelante la “Empresa”).
                            </p>
                            <p>
                                <strong>1.2.</strong> La aceptación de ser Titular no le insume costo alguno.
                            </p>
                            <p>
                                <strong>1.3.</strong> La aceptación de los Términos y Condiciones del Programa se materializará mediante su solicitud de adhesión al Programa. En el caso de las actualizaciones de los Términos y Condiciones del Programa, la aceptación se materializará mediante el uso que el Titular realice de los Beneficios del Programa con posterioridad a la entrada en vigencia de las actualizaciones. Los Términos y Condiciones se aplicarán a todas las transacciones bajo el Programa que efectúe el titular con posterioridad a la aceptación por parte de la Empresa de la solicitud de adhesión escrita o solicitud enviada vía web, la que se exteriorizará a través de un envío de mail con la confirmación.
                            </p>
                            <p>
                                <strong>1.4.</strong> Los Titulares deberán notificar a la brevedad posible a la Empresa, al teléfono que se le informe, o por correo o por medio del Sitio de internet, cualquier cambio en sus datos para su actualización en la base de datos del Programa.
                            </p>
                            <p>
                                <strong>1.5.</strong> La Empresa podrá, sin expresión de causa, rechazar cualquier solicitud de adhesión al Programa.
                            </p>
                            <h2>2) PROGRAMA GRISINO FAN</h2>
                            <p>
                                <strong>2.1.</strong> El Titular tendrá acceso a distintos Beneficios en la compra que se realice en los locales y el E-Shop GRISINO.
                            </p>
                            <p>
                                <strong>2.2.</strong> La Empresa registrará en una cuenta correspondiente a cada Titular la cantidad de Estrellas que los Titulares adquieran. La cuenta estará asociada al DNI del Titular. Los Titulares adquirirán una (1) Estrella por cada novecientos cincuenta (950) pesos de compra que realicen en los locales y el E-Shop GRISINO. También podrán sumar estrellas en eventos, encuestas, Misiones de la APP u otras acciones que la Empresa organice y que no impliquen obligación de compra.
                            </p>
                            <p>
                                <strong>2.3.</strong> La Empresa, a su exclusivo criterio, podrá actualizar el monto de pesos necesarios para obtener una estrella.
                            </p>
                            <p>
                                <strong>2.4.</strong> Las estrellas acumuladas en el Programa no constituyen propiedad de los Clientes. Las estrellas caducarán a los doce (12) meses de su obtención. A su vez, todas las estrellas caducarán con la finalización del Programa. En caso de caducidad la Empresa procederá a la baja de las estrellas de la cuenta del Titular de manera automática y sin necesidad de notificación alguna. Las estrellas no son transferibles a ninguna otra persona o entidad ni bajo ningún título o causa y tampoco podrán ser transferidas a ninguna otra cuenta del Programa.
                            </p>
                            <p>
                                <strong>2.5.</strong> Las Estrellas obtenidas son intransferibles y sólo podrán ser utilizadas por el Titular de la cuenta a la que estén asociadas por su DNI.
                            </p>
                            <p>
                                <strong>2.6.</strong> La Empresa se reserva el derecho de dar de baja la cuenta de los Titulares cuando, a su sólo criterio, se haga un uso indebido o inconveniente del Programa, sin que ello genere responsabilidad alguna para la Empresa.
                            </p>
                            <p>
                                <strong>2.7.</strong> Se considerará que la adhesión al Programa resulta indebida cuando ha sido obtenida por cualquier medio o procedimiento no autorizado por la Empresa. La Empresa procederá a dar de baja a toda cuenta obtenida mediante la adhesión indebida al Programa. La Empresa se reserva el derecho de iniciar las acciones legales que correspondan contra cualquier persona que intente un uso fraudulento del Programa.
                            </p>
                            <p>
                                <strong>2.8.</strong> En todos los casos, la baja de la cuenta implicará la caducidad de las Estrellas acreditadas y la pérdida de cualquier otro beneficio del Programa.
                            </p>
                            <h2>3) BENEFICIOS</h2>
                            <p>
                                <strong>3.1.</strong> El Programa otorga Beneficios a sus Titulares en los locales Grisino, E-Shop y/o comercios adheridos, los que serán comunicados en cada oportunidad.
                            </p>
                            <p>
                                <strong>3.2.</strong> Los Beneficios podrán consistir en cupones de descuento, regalos, descuentos variables, invitaciones, adelantos y prórrogas de temporadas y liquidaciones entre otros, los cuales serán notificados oportunamente a los Titulares por los medios que la Empresa considere adecuados. Algunos beneficios se otorgarán a cambio de estrellas y otros sólo por pertenecer al Programa. La Empresa podrá establecer límites de disponibilidad para utilizar el Beneficio por cada titular, y en dicho caso, al comunicarse el Beneficio se informará el stock disponible.
                            </p>
                            <p>
                                <strong>3.3.</strong> En ningún caso y bajo ninguna circunstancia estará permitido canjear Beneficios por dinero en efectivo.
                            </p>
                            <h2>4) MODO DE USO DEL PROGRAMA Y ACCESO A LOS BENEFICIOS</h2>
                            <p>
                                <strong>4.1.</strong> El Titular podrá acceder a los Beneficios exclusivamente mediante la descarga de la APP Grisino FAN o la Web APP, donde también podrá gestionar sus estrellas y deberá verificar su identidad al momento de ingresar.
                            </p>
                            <p>
                                <strong>4.2.</strong> Para el caso que el Beneficio estuviera asociado a una compra, el Titular sólo podrá obtener el Beneficio otorgado si presenta el DNI en locales o al iniciar sesión en E-Shop antes de la emisión de la factura correspondiente por el producto adquirido.
                            </p>
                            <p>
                                <strong>4.3.</strong> En caso que el sistema se encuentre fuera de servicio por cualquier motivo, la sucursal confeccionará un comprobante de forma manual.
                            </p>
                            </div>
                        </div>

                        <div class="section">
                            <h2>5) PROMOCIONES Y SORTEOS. ACCIONES PUBLICITARIAS</h2>
                            <div class="clause">
                            <p>
                                <strong>5.1.</strong> En forma directa o a través de terceros la Empresa, con la frecuencia que establezca a su exclusivo criterio, podrá realizar promociones y sorteos de bienes y/o servicios, de conformidad con las bases y condiciones que se determinen en cada oportunidad.
                            </p>
                            <p>
                                <strong>5.2.</strong> En dichas promociones y/o sorteos no podrán participar empleados y contratados directos de la Empresa, así como tampoco sus familiares parientes por consanguinidad o afinidad en segundo grado.
                            </p>
                            <p>
                                <strong>5.3.</strong> Adicionalmente, la Empresa podrá emprender acciones publicitarias y promocionales con terceros comercios adheridos al Programa, a fin de acercarle al Titular diversa información, ofertas u otros beneficios que pueden resultar de su interés.
                            </p>
                            <p>
                                <strong>5.4.</strong> La entrega de los Beneficios estará sujeta a disponibilidad de stock. Ninguno de los Beneficios podrá ser canjeado por dinero en efectivo.
                            </p>
                            <h2>6) CANCELACIÓN</h2>
                            <p>
                                <strong>6.1.</strong> La Empresa podrá cancelar o finalizar cualquier adhesión de los Titulares al Programa sin aviso previo y sin expresión de causa, cuando el Titular incurra en alguno de los siguientes supuestos: 
                            </p>
                            <ul class="clause">
                                <li>a) no cumpliera con los Términos y Condiciones;</li>
                                <li>b) abusara de cualquier privilegio concedido bajo el Programa;</li>
                                <li>c) proveyera cualquier información falsa a la Empresa o a cualquier punto de venta adherido;</li>
                                <li>d) pretendiera vender a terceros los beneficios;</li>
                                <li>e) se desvirtúe el uso del programa especificado en el presente, etc.</li>
                            </ul>
                            <p>
                                <strong>6.2.</strong> Finalizada la adhesión por cualquier causa, la información relativa al Titular existente permanecerá en la base de datos de Grisino. El Titular gozará de los derechos conferidos por la Ley N° 25.326 (Ley de Protección de los Datos Personales).
                            </p>
                            <h2>7) CONDICIONES GENERALES</h2>
                            <p>
                                <strong>7.1.</strong> En cualquier momento, la Empresa podrá efectuar cambios a estos Términos y Condiciones, a la denominación del Programa y a su logo, a los Beneficios publicados, las condiciones de participación y la cantidad y características de los locales adheridos. Las modificaciones mencionadas precedentemente, serán informadas a los Titulares por cualquier medio masivo de comunicación, a través del Sitio de Internet o de cualquier otro que implique su difusión pública, a elección de La Empresa.
                            </p>
                            <p>
                                <strong>7.2.</strong> La Empresa podrá efectuar cambios en cualquier momento en los Beneficios incluidos en el Programa y en las condiciones para el acceso a dichos Beneficios y la vigencia de los mismos.
                            </p>
                            <p>
                                <strong>7.3.</strong> La información concerniente a los Titulares y la referida a las transacciones que resulten en la utilización del documento por el programa (en adelante la "Información") será almacenada y custodiada por la Empresa. La Información estará a disposición de la Empresa para su utilización dentro del Programa y en tanto resulte necesario, estará a disposición de los establecimientos adheridos autorizados de la Empresa y con fines publicitarios y promocionales, tal como se indica en el apartado 5) de los presentes Términos y Condiciones del Programa. El Programa utiliza los datos para conocer los intereses y/o afinidades de los titulares de tal forma que los Beneficios se adecuen a los intereses de los mismos y para el máximo rendimiento del Programa.
                            </p>
                            <p>
                                <strong>7.4.</strong> El Titular expresamente acepta y acuerda:
                            </p>
                            <ul class="clause">
                                <li>(a) Proveer la información solicitada para adherirse al Programa y autorizar a la Empresa al acceso y tratamiento de la información allí contenida;</li>
                                <li>(b) Que la Empresa trate y/o transfiera los datos a los establecimientos adheridos, a fin de que los mismos le envíen al Titular diversa información, ofertas y otros beneficios que pueden resultar de su interés. El Titular podrá revocar dicha autorización según lo establecido en el punto 7.7.</li>
                                <li>(c) Que frente a un reclamo de un Titular, la Empresa utilice la información obrante en su base de datos.</li>
                            </ul>
                            <p>
                                <strong>7.5.</strong> La Empresa podrá terminar o suspender el Programa en cualquier momento, notificando dicha decisión con un mínimo de treinta (30) días de anticipación a la fecha de terminación o en su caso de suspensión, por cualquier medio masivo de comunicación.
                            </p>
                            <p>
                                <strong>7.6.</strong> Cualquier comunicación cursada por la Empresa a un Titular se considerará notificada si fue remitida al domicilio o dirección de e-mail del mismo obrante en los registros de los Titulares de la base de datos del Programa.
                            </p>
                            <p>
                                <strong>7.7.</strong> El Cliente, al adherirse al Programa, brinda su conformidad y autoriza a la Empresa a enviarle y trasmitirle todo tipo de comunicaciones, avisos y mensajes que guarden relación con el Programa y con los fines publicitarios y promocionales a los domicilios mencionados precedentemente, como así también a las direcciones de e-mail y teléfonos, que figuren en los registros de los titulares. El Titular podrá revocar dicha autorización manifestando por escrito al domicilio de la Empresa, Almirante F. J. Segui 1581, Ciudad de Buenos Aires, Argentina, por teléfono al 4582-4555 o por mail a <a href="mailto:contactoweb@grisino.com">contactoweb@grisino.com</a> expresando su deseo de no recibir aquellas comunicaciones.
                            </p>
                            <p>
                                <strong>7.8.</strong> El Programa será válido únicamente en los puntos de venta de los establecimientos adheridos. El Programa podrá ser extendido a otros países.
                            </p>
                            <p>
                                <strong>7.9.</strong> “La Empresa” tiene su domicilio comercial en Almirante F. J. Segui 1581, Ciudad de Buenos Aires, Argentina.
                            </p>
                            <p>
                                <strong>7.10.</strong> Cualquier exclusión o limitación de responsabilidad contenida en el presente en favor de la Empresa se extiende a cada uno de sus socios y sus personas jurídicas vinculadas. El término "persona jurídica vinculada" tiene el significado dado por la Ley Nº 19.550 de Sociedades Comerciales.
                            </p>
                            <p>
                                <strong>7.11.</strong> La eventual nulidad de alguna de las cláusulas de los Términos y Condiciones no importará la nulidad de las restantes cláusulas.
                            </p>
                            <p>
                                <strong>7.12.</strong> La Empresa se reserva el derecho de variar los Términos y Condiciones, como así también los Beneficios, en tanto fuera necesario, durante el desarrollo del Programa.
                            </p>
                            <p>
                                <strong>7.13.</strong> La participación en el Programa implica la aceptación de todas las condiciones estipuladas en los presentes Términos y Condiciones, los que se reputan conocidos por los Titulares.
                            </p>
                            <p>
                                <strong>7.14.</strong> Cualquier cuestión que se suscite con el Titular en relación al Programa, será resuelto en forma definitiva e inapelable por la Empresa.
                            </p>
                            <p>
                                <strong>7.15.</strong> El Titular fija su domicilio en el informado en la solicitud de adhesión al programa y la Empresa en el informado en el presente, y acuerdan que para cualquier disputa o divergencia derivada de los presentes Términos y Condiciones del Programa, somete la misma a la jurisdicción de los Tribunales Nacionales Ordinarios de la Ciudad Autónoma de Buenos Aires y a la competencia según la materia.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => ({
    auth: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Terms);
